<template>
  <div>
    <p class="subtitle has-text-dark">¡Bienvenido nuevamente!</p>
    <form-sign-in />
    <p class="pt-3 has-text-right" v-if="nombre_comercial">
      <a :href="sitio_web ? sitio_web : `/`" :title="nombre_comercial" :target="sitio_web ? `_blank` : `_self`"
        ><b-icon icon="globe" /> {{nombre_comercial}}</a
      >
    </p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import FormSignIn from "../components/FormSignIn";
export default {
  components: {
    FormSignIn,
  },
  computed:{
    ...mapGetters({
      nombre_comercial: "entitie/nombre_comercial",
      sitio_web: "entitie/sitio_web",
    }),
  }
};
</script>
