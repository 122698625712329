<template>
  <form-observer ref="observer" @submit.prevent="onSubmit">
    <input-base
      rules="required"
      label="Usuario"
      maxlength="50"
      placeholder="usuario"
      icon="user"
      v-model="form.username"
      focus
    />

    <input-base
      rules="required"
      label="Contraseña"
      type="password"
      placeholder="Escriba su contraseña"
      password-reveal
      maxlength="50"
      icon="lock"
      v-model="form.password"
    />
    <b-button
      native-type="submit"
      type="is-primary"
      expanded
      icon-left="sign-in-alt"
      :loading="loading"
      class="mt-3"
      >Ingresar</b-button
    >
  </form-observer>
</template>

<script>
export default {
  data() {
    return {
      form: {},
      loading: false,
    };
  },
  methods: {
    async onSubmit() {
      const isValid = await this.$refs.observer.validate();
      if (!isValid) return;
      //
      this.loading = true;
      const user = await this.$store.dispatch("auth/SignInRequest", this.form);
      this.loading = false;
      if (user) this.$router.push("/");
    },
  },
};
</script>
